<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Mes médias</h1>
			<p class="text-muted">Uploadez et renommez vos médias pour les afficher en lien sur votre profil public</p>
		</div>

		<div class="p-col-12">
			<div class="card">

				<div class="hidden" v-show="false">
					<input accept=".jpg,.jpeg,.png,.pdf" @change="uploadData" ref="inputFile" type="file" >
				</div>

				<div v-if="filesList !== undefined" class="p-grid">
					<div class="p-col-12">
						<InfiniteProgress v-show="loading"></InfiniteProgress>
						<DataView :value="filesList" :layout="layout" :paginator="true" :rows="9" :sortOrder="sortOrder" :sortField="sortField">
							<template #header>
								<div class="p-grid p-nogutter">
									<div class="p-col-12 p-sm-3" style="text-align: left">
										<Button :disabled="loading || quota_used >= quota" @click.prevent="uploadNew"
												class="p-button b-button" label="Uploader un média"
												icon="pi pi-plus"></Button>
									</div>
									<div class="p-col-12 p-sm-6" style="text-align: center">
										<span v-if="quota !== undefined" class="quota">Média(s) : {{ quota_used }}/{{ quota }}</span>
									</div>
									<div class="p-col-12 p-sm-3" style="text-align: right">
										<DataViewLayoutOptions v-model="layout" />
									</div>
								</div>
							</template>

							<template #list="slotProps">
								<div class="p-col-12">
									<div class="product-list-item">

										<div class="product-list-detail">
											<div class="product-name">
												<div v-if="editUuid !== slotProps.data.s3uuid" class="name"><a @click.prevent="editMode(slotProps.data)" href="#">{{ slotProps.data.name }}</a></div>
												<div v-else class="name-edit">
													<div class="p-col-12">
														<div class="p-inputgroup">
															<InputText v-model="slotProps.data.name"/>
															<Button class="p-button-warning"  @click.prevent="cancelUpdate(slotProps.data)"  icon="pi pi-times"/>
															<Button class="p-button-success" @click.prevent="updateFile(slotProps.data)" icon="pi pi-check"/>
														</div>
													</div>
												</div>
											</div>
											<div class="product-description">Mis en ligne le {{ tmsToDate(slotProps.data.updDate) }}</div>
											<span class="product-category">{{ slotProps.data.extension }}</span>
										</div>
										<div class="product-list-action">
											<Button class="p-button-danger" @click.prevent="deleteFile(slotProps.data)" icon="pi pi-trash"></Button>
											<Button @click.prevent="downloadFile(slotProps.data)" icon="pi pi-download"></Button>
										</div>
									</div>
								</div>
							</template>

							<template #grid="slotProps">
								<div class="p-col-12 p-md-4">
									<div class="product-grid-item card">
										<div class="product-grid-item-top">
											Média <b>{{ slotProps.data.extension }}</b>
										</div>
										<div class="product-grid-item-content">

											<div class="product-name">
												<div v-if="editUuid !== slotProps.data.s3uuid" class="name"><a @click.prevent="editMode(slotProps.data)" href="#">{{ slotProps.data.name }}</a></div>
												<div v-else class="name-edit">
													<div class="p-col-12">
														<div class="p-inputgroup">
															<InputText v-model="slotProps.data.name"/>
															<Button class="p-button-warning"  @click.prevent="cancelUpdate(slotProps.data)"  icon="pi pi-times"/>
															<Button class="p-button-success" @click.prevent="updateFile(slotProps.data)" icon="pi pi-check"/>
														</div>
													</div>
												</div>
											</div>

											<div class="product-description">Mis en ligne le {{ tmsToDate(slotProps.data.updDate) }}</div>

										</div>
										<div class="product-grid-item-bottom">
											<Button class="p-button-danger" @click.prevent="deleteFile(slotProps.data)" icon="pi pi-trash"></Button>
											<Button @click.prevent="downloadFile(slotProps.data)" icon="pi pi-download" class="p-button p-button-secondary"></Button>
										</div>
									</div>
								</div>
							</template>
						</DataView>
					</div>





				</div>
				<div v-else class="p-grid">
					<div class="p-col-12">
						Pas de médias
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex';
	import userMixin from "../mixins/userMixin";
	import InfiniteProgress from '../components/InfiniteProgress.vue';
	import calendarMixin from "../mixins/calendarMixin";

export default {
	data(){
		return {
			editUuid: undefined,
			baseName: undefined,
			filesList: undefined,
			layout: 'grid',
			sortKey: null,
			sortOrder: null,
			sortField: null,
			sortOptions: [
				{label: 'Price High to Low', value: '!price'},
				{label: 'Price Low to High', value: 'price'},
			],
			type: "TYPE_PUBLIC",
			quota: undefined,
			quota_used: undefined,
			loading: false,
		}
	},
	mounted(){
		this.waitUser();
	},
	methods: {
		uploadData() {
			const vm = this;

			if(this.$refs.inputFile.files.length <= 0){
				return;
			}

			let file = this.$refs.inputFile.files[0];
			if(file == undefined || file === null){
				return;
			}

			let filename = this.$refs.inputFile.files[0].name;

			let fd = new FormData();
			fd.append("mfile", file);
			fd.append("filename", filename);
			fd.append("type", vm.type);
			fd.append('extension', filename.split('.').pop());
			vm.loading = true;
			window.appData.$http.post('/apx/upd/file',
					fd,
					{
						headers: {
							'Content-Type': 'multipart/form-data',
						}
					}
			)
					.then(function(data){
						//console.log(data, 'SUCCESS!!');
						//done replace this.innerValue
						if(data.error == true){
							throw new Error(data.message);
						}
						vm.loading = false;
						vm.loadFiles();
					})
					.catch(function(err){
						console.warn('FAILURE!!',err);
						//todo show error message
						vm.loading = false;
					});

		},
		editMode(File){
			this.editUuid = File.s3uuid;
			this.baseName = File.name;
		},
		cancelUpdate(File){
			File.name = this.baseName;
			this.editUuid = undefined;
			this.baseName = undefined;
		},
		updateFile(File){
			const vm = this;
			if(File === undefined){
				return;
			}
			vm.loading = true;
			window.appData.$http.put('/api/files/' + File.id, {
				name: File.name,
			})
					.then(res => {
						if(res.data === undefined || res.data.error == true){
							throw new Error(res.data.message || 'An error occured');
						}
						vm.loading = false;
						vm.loadFiles();
						vm.editUuid = undefined;
						vm.baseName = undefined;
					})
					.catch(err => {
						console.error(err);
						//todo display toast error
						vm.loading = false;
						return {
							error: true,
							data: err.response.data,
							message: err.message
						};
					})
		},
		deleteFile(File){
			const vm = this;
			if(File === undefined){
				return;
			}
			vm.loading = true;
			window.appData.$http.delete('/apx/upd/file/public/' + File.id)
					.then(res => {
						if(res.data === undefined || res.data.error == true){
							throw new Error(res.data.message || 'An error occured');
						}
						vm.loading = false;
						vm.loadFiles();
					})
					.catch(err => {
						console.error(err);
						//todo display toast error
						vm.loading = false;
						return {
							error: true,
							data: err.response.data,
							message: err.message
						};
					})
		},
		uploadNew() {
			this.$refs.inputFile.click();
		},
		downloadFile(File){
			let link = document.createElement("a");
			// If you don't know the name or want to use
			// the webserver default set name = ''
			link.setAttribute('target', '_blank');
			link.href = File.bucketUrl + '/' + File.guid + '.' + File.extension;
			document.body.appendChild(link);
			link.click();
			link.remove();
		},
		onSortChange(event){
			const value = event.value.value;
			const sortValue = event.value;

			if (value.indexOf('!') === 0) {
				this.sortOrder = -1;
				this.sortField = value.substring(1, value.length);
				this.sortKey = sortValue;
			}
			else {
				this.sortOrder = 1;
				this.sortField = value;
				this.sortKey = sortValue;
			}
		},
		waitUser(){
			const vm = this;
			vm.loading = true;
			if(vm.userData === undefined){
				setTimeout(() => {
					vm.waitUser();
				}, 100);
				return;
			}
			vm.loading = false;
			vm.loadFiles()
		},
		loadFiles(){
			const vm = this;
			vm.loading = true;
			window.appData.$http.get('/apx/upd/file/public')
					.then(res => {
						if(res.data === undefined || res.data.error == true){
							throw new Error(res.data.message || 'An error occured');
						}
						vm.filesList = res.data.files;
						vm.quota = res.data.quota;
						vm.quota_used = res.data.quota_used;
						vm.loading = false;
					})
					.catch(err => {
						console.error(err);
						vm.loading = false;
						return {
							error: true,
							data: err.response.data,
							message: err.message
						};
					})
			;
		}
	},
	computed: {
		...mapState({
			userData: 'user',
		})
	},
	mixins: [userMixin, calendarMixin],
	components: {
		InfiniteProgress
	}
}
</script>

<style lang="scss" scoped>
	.product-name {
		font-size: 1.5rem;
		font-weight: 700;
	}

	.product-description {
		margin: 0 0 1rem 0;
	}

	.product-category-icon {
		vertical-align: middle;
		margin-right: .5rem;
	}

	.product-category {
		font-weight: 600;
		vertical-align: middle;
	}

	::v-deep(.product-list-item) {
		display: flex;
		align-items: center;
		padding: 1rem;
		width: 100%;

	img {
		width: 150px;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin-right: 2rem;
	}

	.product-list-detail {
		flex: 1 1 0;
	}

	.p-rating {
		margin: 0 0 .5rem 0;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
		margin-bottom: .5rem;
		align-self: flex-end;
	}

	.product-list-action {
		display: flex;
		flex-direction: column;
	}

	.p-button {
		margin-bottom: .5rem;
	}

	}

	.product-badge {
		border-radius: 2px;
		padding: .25em .5rem;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 12px;
		letter-spacing: .3px;

	&.status-instock {
		 background: #C8E6C9;
		 color: #256029;
	 }

	&.status-outofstock {
		 background: #FFCDD2;
		 color: #C63737;
	 }

	&.status-lowstock {
		 background: #FEEDAF;
		 color: #8A5340;
	 }
	}

	::v-deep(.product-grid-item) {
		margin: .5em;
		border: 1px solid #dee2e6;

	.product-grid-item-top,
	.product-grid-item-bottom {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	img {
		width: 75%;
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
		margin: 2rem 0;
	}

	.product-grid-item-content {
		text-align: center;
	}

	.product-price {
		font-size: 1.5rem;
		font-weight: 600;
	}
	}

	@media screen and (max-width: 576px) {
		.product-list-item {
			flex-direction: column;
			align-items: center;

		img {
			width: 75%;
			margin: 2rem 0;
		}

		.product-list-detail {
			text-align: center;
		}

		.product-price {
			align-self: center;
		}

		.product-list-action {
			display: flex;
			flex-direction: column;
		}

		.product-list-action {
			margin-top: 2rem;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
	}
	}
</style>
